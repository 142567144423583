<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
};

// const width = document.documentElement.clientWidth
// const css = `
//         html{
//         font-size: ` + width / 8 + `px;
//         }
//         `
// document.write(`<style>` + css + `</style>`)
</script>

<style lang="scss">
#app {
  height: 100%;
  position: relative;
  width: 100%;
}
</style>
