import Cookies from 'js-cookie';
// cookie保存的天数
import config from '@/config';

export const TOKEN_KEY = 'token';

export const setToken = token => {
  Cookies.set(TOKEN_KEY, token, {
    expires: config.cookieExpires || 1
  });
};

export const getToken = () => {
  const token = Cookies.get(TOKEN_KEY);
  if (token) {
    if (token === 'undefined') {
      return false;
    }
    return token;
  } else return false;
};

export const setCookie = (key, value, expire) => {
  Cookies.set(key, value, {
    expires: expire || config.cookieExpires || 1
  });
};

export const getCookie = (key, defaultValuefunc) => {
  const value = Cookies.get(key);
  if (defaultValuefunc && typeof defaultValuefunc === 'function') {
    return defaultValuefunc(value);
  } else {
    return value || null;
  }
};

/**
 * @param {String} url
 * @description 从URL中解析参数
 */
export const getParams = url => {
  const keyValueArr = url.split('?')[1].split('&');
  const paramObj = {};
  keyValueArr.forEach(item => {
    const keyValue = item.split('=');
    paramObj[keyValue[0]] = keyValue[1];
  });
  return paramObj;
};

/**
 * @param {String} separator 分隔符
 * @param {String} imgString 要分割的字符串
 * @description 按照分隔符从图片地址字符串中分隔出正确的图片地址
 */
export const splitImgSrc = (separator, imgString) => {
  imgString = imgString || '';
  var imgSrcObjrct = imgString.split(separator) || [];
  imgSrcObjrct = removeEmpty(imgSrcObjrct);
  return imgSrcObjrct;
};

/**
 * @param {obj} Object 一个对象
 * @description 去除对象中属性值为空的属性
 */
const removeEmpty = obj => {
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object') {
      removeEmpty(obj[key]);
    } else if (obj[key] === null || obj[key] === '' || obj[key] === undefined) {
      obj.splice(key, 1);
    }
  });
  return obj;
};
