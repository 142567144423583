import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Element from 'element-ui';
import '@/assets/style/index.scss';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/lib/tagcanvas.js';

// 移动端适配
import 'lib-flexible/flexible.js';

import { getCookie, setCookie } from './helper/util';
Vue.config.productionTip = false;

Vue.use(Element);

// linx 必须全局，否则_getImage 无法获取到，暂时没找到原因
window.vgetCookie = getCookie;
window.vsetCookie = setCookie;

Vue.mixin({
  methods: {
    handleResponse: function(res) {
      if (res.data) {
        if (res.data.code === 401) {
          this.$router.push({
            path: '/'
          });
        } else {
          return res.data;
        }
      } else if (res.response && res.response.data && res.response.data.msg) {
        alert(res.response.data.msg);
        return null;
      } else if (res.response && res.response.status) {
        alert(res.response.statusText);
        return null;
      } else if (res.timeout) {
        alert('网络链接超时,请稍后再试！');
        return null;
      }
    }
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
