import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/index',
    name: 'Home',
    component: () => import('@/views/home/index')
  },
  {
    path: '/',
    name: 'Index',
    component: () => import('@/views/index')
  },
  {
    path: '/prizelist',
    name: 'Prizelist',
    component: () => import('@/views/prizelist/prizelist.vue')
  },
  {
    path: '/acdetail',
    name: 'acdetail',
    component: () => import('@/views/acdetail/acdetail.vue')
  },
  {
    path: '/selectall',
    name: 'selectall',
    component: () => import('@/views/acdetail/selectall/selectall.vue')
  }
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'hash',
  routes
});

export default router;
